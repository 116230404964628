import { AiFillPhone, AiOutlineWhatsApp } from 'react-icons/ai';
import { TrailerPreview } from '../../models/TrailerPreview';
import { EventService } from '../../services/EventService';
import LinkButton from '../Buttons/LinkButton';
import Chip from '../Chip/Chip';
import styles from './TrailerCard.module.scss';

type PropsType = {
  trailer: TrailerPreview;
};

const TrailerCard = ({ trailer }: PropsType) => {
  const handlePhoneButton = () => {
    EventService.CreateStringEventAsync(`${trailer.Name.replaceAll(' ', '')}Phone`);
  };

  const handleWhatsAppButton = () => {
    EventService.CreateStringEventAsync(`${trailer.Name.replaceAll(' ', '')}WhatsApp`);
  };

  const getWhatsAppLink = () => {
    return 'https://wa.me/4917660278683?text=Hallo%2C%0A%0Aich%20interessiere%20mich%20f%C3%BCr%20den%20%2AXXX%2A.%0A%0AW%C3%A4re%20er%20im%20Zeitraum%20vom%20%2A%5BDatum%20%26%20Uhrzeit%5D%2A%20bis%20%2A%5BDatum%20%26%20Uhrzeit%5D%2A%20verf%C3%BCgbar%3F%20%0A%0AWas%20w%C3%BCrde%20mich%20das%20kosten%3F%20%0A%0AVielen%20Dank%20im%20Voraus%21'.replace(
      'XXX',
      trailer.Name
    );
  };

  return (
    <div className={styles.container}>
      <img className={styles.image} src={trailer.Image} alt={trailer.Name} />
      <div className={styles.trailerDataContainer}>
        <p className={styles.title}>{trailer.Name}</p>
        <span className={styles.price}>ab {trailer.Price} €</span>
        <div className={styles.chipsContainer}>
          {trailer.Tags.map((v, i) => (
            <Chip key={i} text={v} />
          ))}
        </div>
        <div className={styles.actionButtonsContainer}>
          <LinkButton text='Jetzt anrufen' primary link='tel:+4917660278683' icon={<AiFillPhone />} onClick={() => handlePhoneButton()} />
          <LinkButton text='Jetzt schreiben' link={getWhatsAppLink()} icon={<AiOutlineWhatsApp />} onClick={() => handleWhatsAppButton()} />
        </div>
      </div>
    </div>
  );
};

export default TrailerCard;
