export class AnimationService {
  private mDocument: Document | undefined;
  private mElements: NodeListOf<Element> | undefined;
  private mScrolled: boolean = true;
  private mIntervalHandle: NodeJS.Timeout | undefined;

  private scrollHandlerFunction: () => void;
  private scrollCallbackFunction: () => void;

  constructor() {
    this.scrollHandlerFunction = this.scrollHandler.bind(this);
    this.scrollCallbackFunction = this.scrollCallback.bind(this);
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public init(): void {
    this.mDocument = document;

    if (this.isMobile()) return;

    this.mElements = this.mDocument.querySelectorAll('.xanimate');
  }

  public start(): void {
    if (!this.mElements || this.mElements.length === 0) return;

    this.mElements.forEach((element) => {
      this.applyStyle(element, null, true);
    });

    window.addEventListener('scroll', this.scrollHandlerFunction, false);
    window.addEventListener('resize', this.scrollHandlerFunction, false);
    this.mIntervalHandle = setInterval(this.scrollCallbackFunction, 50);
  }

  public stop(): void {
    if (!this.mElements) return;

    window.removeEventListener('scroll', this.scrollHandlerFunction, false);
    window.removeEventListener('resize', this.scrollHandlerFunction, false);
    if (this.mIntervalHandle) clearInterval(this.mIntervalHandle as NodeJS.Timeout);
  }

  public scrollHandler(): void {
    this.mScrolled = true;
  }

  public scrollCallback(): void {
    if (!this.mScrolled) return;

    this.mScrolled = false;

    if (!this.mElements) return;

    this.mElements.forEach((element) => {
      if (this.isElementInViewport(element)) {
        const delay = element.getAttribute('data-xanimate-delay');
        this.applyStyle(element, delay, false);
        element.classList.add('animated');
      }
    });
  }

  public isElementInViewport(element: Element): boolean {
    if (!this.mDocument) return false;

    const rect = element.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || this.mDocument.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || this.mDocument.documentElement.clientWidth)
    );
  }

  public applyStyle(element: Element, delay: string | null, hidden: boolean) {
    let style = hidden ? 'visibility: hidden; -webkit-animation-name: none; -moz-animation-name: none; animation-name: none;' : 'visibility: visible;';

    if (delay) {
      style += '-webkit-animation-delay: ' + delay + '; -moz-animation-delay: ' + delay + '; animation-delay: ' + delay + ';';
    }

    element.setAttribute('style', style);
  }
}
