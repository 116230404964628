import autoImg from '../../assets/Autotransporter.webp';
import gitterImg from '../../assets/Gitter.webp';
import motorradImg from '../../assets/Motorradtransporter.webp';
import kastenImg from '../../assets/kastenanhaenger_hoch.webp';
import { TrailerPreview } from '../../models/TrailerPreview';
import Carousel from '../Carousel/Carousel';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import TrailerCard from '../TrailerCard/TrailerCard';
import styles from './Trailers.module.scss';

const trailers = [
  new TrailerPreview({ Name: 'Kastenanhänger mit Plane', Image: kastenImg, Price: 25, Tags: ['300Lx150Bx140H', '100km/h', 'gebremst', '1300kg'] }),
  new TrailerPreview({ Name: 'Autotransporter', Image: autoImg, Price: 40, Tags: ['400Lx200B', '100km/h', 'gebremst', '2700kg'] }),
  new TrailerPreview({ Name: 'Motorradtransporter', Image: motorradImg, Price: 25, Tags: ['100km/h', 'gebremst', '750kg'] }),
  new TrailerPreview({ Name: 'Kastenanhänger mit Gitteraufsatz', Image: gitterImg, Price: 30, Tags: ['250Lx131Bx90H', 'gebremst', '1300kg'] })
];

type PropsType = {};

//eslint-disable-next-line
const Trailers = ({}: PropsType) => {
  const getCarouselItems = () => {
    return trailers.map((val, idx) => {
      return <TrailerCard key={idx} trailer={val} />;
    });
  };

  return (
    <div className={styles.container}>
      <ContainerTitle title={'Unsere Anhänger'} />
      <p className={[styles.description, 'xanimate fadeInUp'].join(' ')} data-xanimate-delay='0.3s'>
        Ganz gleich, wofür Sie einen Anhänger benötigen – bei uns finden Sie das richtige Modell! Ob <strong>Kastenanhänger</strong>, <strong>Gitteranhänger</strong>,{' '}
        <strong>Autotransporter</strong> oder <strong>Motorradanhänger</strong>: Unser Anhängerverleih bietet Ihnen eine große Auswahl an hochwertigen PKW-Anhängern für jeden
        Einsatzbereich.
      </p>
      <Carousel carouselItems={getCarouselItems()} />
      {/* <Accordion itemList={trailerList} /> */}
    </div>
  );
};

export default Trailers;
