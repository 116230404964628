import { AiFillPhone } from 'react-icons/ai';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import image from '../../assets/kastenanhaenger_hoch.webp';
import { EventService, EventType } from '../../services/EventService';
import LinkButton from '../Buttons/LinkButton';
import SubTitle from '../SubTitle/SubTitle';
import Title from '../Title/Title';
import styles from './Head.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Head = ({}: PropsType) => {
  const handlePhoneButton = () => {
    EventService.CreateEventAsync(EventType.HeaderPhone);
  };

  const handleEmailButton = () => {
    EventService.CreateEventAsync(EventType.HeaderEmail);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <Title title={'Anhänger Balu - Anhängerverleih in Stuttgart'} />
        <SubTitle title={'Benötigen Sie einen Anhänger? Mieten Sie bei uns den passenden Anhänger für Umzüge, Transporte und mehr – schnell, einfach und zu fairen Preisen! '} />
        <div className={styles.iconsContainer}>
          <LinkButton text={'Anrufen'} link={'tel:+4917660278683'} icon={<AiFillPhone />} primary onClick={handlePhoneButton} />
          <LinkButton text={'Reservieren'} link={'mailto:info@anhaenger-balu.de'} icon={<HiOutlineEnvelope />} onClick={handleEmailButton} />
        </div>
      </div>
      <div className={styles.rightContainer}>
        <img className={styles.image} src={image} alt={'Planenanhänger hoch'} />
      </div>
    </div>
  );
};

export default Head;
