import Contact from '../components/Contact/Contact';
import Footer from '../components/Footer/Footer';
import Head from '../components/Head/Head';
import Services from '../components/Services/Services';
import Trailers from '../components/Trailers/Trailers';
import WhatsAppButton from '../components/WhatsAppButton/WhatsAppButton';
import Container from '../containers/Container';
import Section from '../containers/Section/Section';
import Layout from '../layout/Layout';
import PageWrapper from './PageWrapper';

type PropsType = {};

//eslint-disable-next-line
const MainPage = ({}: PropsType) => {
  return (
    <PageWrapper>
      <Layout>
        <Section id={''}>
          <Container>
            <Head />
          </Container>
        </Section>
        <Section id='service' bgColor={'#f9f9f9'}>
          <Container>
            <Services />
          </Container>
        </Section>
        <Section id='anhaenger'>
          <Container>
            <Trailers />
          </Container>
        </Section>
        <Section id='contact' bgColor={'#f9f9f9'}>
          <Container>
            <Contact />
          </Container>
        </Section>
        <Footer />
        <WhatsAppButton />
      </Layout>
    </PageWrapper>
  );
};

export default MainPage;
