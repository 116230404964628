import { AiOutlineClockCircle, AiOutlineEnvironment, AiOutlineInfoCircle, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import logo from '../../assets/logo.webp';
import { EventService, EventType } from '../../services/EventService';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import Logo from '../Logo/Logo';
import styles from './Contact.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Contact = ({}: PropsType) => {
  const handlePhoneButton = () => {
    EventService.CreateEventAsync(EventType.FooterPhone);
  };

  const handleEmailButton = () => {
    EventService.CreateEventAsync(EventType.FooterEmail);
  };

  return (
    <div className={styles.container}>
      <ContainerTitle title={'Kontakt'} />
      <div className={styles.rowContainer}>
        <div className={styles.columnContainer}>
          <div className={styles.logoContainer}>
            <Logo logo={logo} />
          </div>
          <p className={styles.sloganText}>Ihre Anhängervermietung in Stuttgart für jeden Bedarf. Vertrauen Sie auf Qualität, Service und flexible Lösungen.</p>
        </div>
        <div className={styles.columnContainer}>
          <p className={styles.title}>Öffnungszeiten</p>
          <p>
            <AiOutlineClockCircle />
            Mo - So: 8 - 21 Uhr
          </p>
          <p>
            <AiOutlineInfoCircle />
            Termine nach telefonischer Absprache
          </p>
        </div>

        <div className={styles.columnContainer}>
          <p className={styles.title}>Adresse</p>
          <p>
            <AiOutlineEnvironment />
            <a href={'https://goo.gl/maps/hRe7ja69ZxtqFyHi8'}>
              Anhänger Balu
              <br />
              Deidesheimer Straße 29
              <br />
              70499 Stuttgart
            </a>
          </p>
          <p>
            <AiOutlinePhone />
            <a href={'tel:+4917660278683'} onClick={handlePhoneButton}>
              {' '}
              +49 17660278683
            </a>
          </p>
          <p>
            <AiOutlineMail />
            <a href={'mailto:info@anhaenger-balu.de'} onClick={handleEmailButton}>
              {' '}
              info@anhaenger-balu.de
            </a>
          </p>
          <p>
            <AiOutlineInfoCircle />
            <a href={'/impressum'}> Impressum & Datenschutz</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
