import easyImg from '../../assets/Handshake_Two Color.webp';
import cheapImg from '../../assets/Money jar_Two Color.webp';
import flexibleImg from '../../assets/Super Man_Two Color.webp';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import Service from './Service/Service';
import styles from './Services.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Services = ({}: PropsType) => {
  return (
    <div className={styles.container}>
      <ContainerTitle title={'Unser Service'} />
      <p className={[styles.description, 'xanimate fadeInUp'].join(' ')} data-xanimate-delay='0.3s'>
        Für Umzüge, Gartenarbeiten oder den Transport sperriger Gegenstände reicht der Platz im eigenen Auto oft nicht aus. In solchen Fällen bietet ein PKW-Anhänger die perfekte
        Lösung. Bei uns, Ihrem spezialisierten Anhängerverleih im Stuttgarter Norden, können Sie hochwertige Anhänger zu günstigen Konditionen mieten.
      </p>
      <div className={styles.servicesContainer}>
        <Service img={easyImg} title={'Einfach'} description={'In nur wenigen Schritten, kriegen Sie bei uns, Ihren gewünschten Anhänger.'} delay='0.6s' />
        <Service img={flexibleImg} title={'Flexibel'} description={'In dringenden Fällen können auch außerhalb der Geschäftszeiten Termine vereinbart werden. '} delay='0.9s' />
        <Service img={cheapImg} title={'Preisgünstig'} description={'Ausgezeichnete Qualität für einen unschlagbaren Preis. '} delay='1.2s' />
      </div>
    </div>
  );
};

export default Services;
