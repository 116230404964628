import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import styles from './Carousel.module.scss';
import CarouselProgress from './CarouselProgress/CarouselProgress';

type PropsType = {
  carouselItems: JSX.Element[];
};

const Carousel = ({ carouselItems }: PropsType) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const increaseCurrentIndex = () => {
    if (currentIndex === carouselItems.length - 1) {
      setCurrentIndex(0);
      return;
    }

    setCurrentIndex(currentIndex + 1);
  };

  const decreaseCurrentIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(carouselItems.length - 1);
      return;
    }

    setCurrentIndex(currentIndex - 1);
  };

  const getCarouselItems = () => {
    return carouselItems.map((val, idx) => {
      return (
        <div key={idx} className={[styles.carouselItemContainer, currentIndex === idx ? styles.activeCarouselItemContainer : ''].join(' ')}>
          {val}
        </div>
      );
    });
  };

  return (
    <div className={[styles.container, 'xanimate fadeInUp'].join(' ')} data-xanimate-delay='0.6s'>
      <div className={styles.buttonContainerLeft} onClick={decreaseCurrentIndex}>
        <FiChevronLeft size={20} />
      </div>
      <div className={styles.carouselContainer}>{getCarouselItems()}</div>
      <div className={styles.buttonContainerRight} onClick={increaseCurrentIndex}>
        <FiChevronRight size={20} />
      </div>

      <CarouselProgress currentIndex={currentIndex} numberOfItems={carouselItems.length} onClick={setCurrentIndex} />
    </div>
  );
};

export default Carousel;
