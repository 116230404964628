import styles from './ContainerTitle.module.scss';

type PropsType = {
  title: string;
};

const ContainerTitle = ({ title }: PropsType) => {
  return (
    <div className={[styles.container, 'xanimate fadeInUp'].join(' ')}>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
};

export default ContainerTitle;
