import { useEffect } from 'react';
import { AnimationService } from '../services/AnimationService';
import { Log } from '../services/Log';
import '../styles/Animate.css';

type PropsType = {
  children: any;
};

const PageWrapper = ({ children }: PropsType) => {
  useEffect(() => {
    Log.Debug('PageWrapper mounted');
    const animationService = new AnimationService();
    animationService.init();
    animationService.start();

    return () => {
      Log.Debug('PageWrapper unmounted');
      animationService.stop();
    };
  }, []);

  return <>{children}</>;
};

export default PageWrapper;
