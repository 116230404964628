import styles from './Service.module.scss';

type PropsType = {
  img: string;
  title: string;
  description: string;
  delay?: string;
};

const Service = ({ img, title, description, delay }: PropsType) => {
  return (
    <div className={[styles.container, 'xanimate fadeInUp'].join(' ')} data-xanimate-delay={delay}>
      <img className={styles.image} src={img} alt={title} />
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default Service;
